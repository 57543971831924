
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { Action, Getter, Mutation } from 'vuex-class';
    import ScenarioStatus from '@/components/main/ScenarioStatus.vue';
    import MapLegend from '@/components/main/MapLegend.vue';
    

    const namespace = 'main';

    @Component({
        components: {
            ScenarioStatus,
            MapLegend,
        },
    })
    export default class FloatingMenu extends Vue {
        @Prop({ default: null }) public legendData: any;

        @Getter('getArea', { namespace }) getArea: any;
        @Getter('getAreaSetsVisible', { namespace }) getAreaSetsVisible: any;
        @Getter('getCatchmentArea', { namespace }) getCatchmentArea: any;
        @Getter('getFileFormatCulture') getFileFormatCulture!: any;
        @Getter('getFilterLocations', { namespace }) getFilterLocations: any;
        @Getter('getFilterScenarios', { namespace }) getFilterScenarios: any;
        @Getter('getFilteredLocations', { namespace }) getFilteredLocations: any;
        @Getter('getFilteredScenarios', { namespace }) getFilteredScenarios: any;
        @Getter('getHeatMapSetsVisible', { namespace }) getHeatMapSetsVisible: any;
        @Getter('getIsoLineMinutes', { namespace }) getIsoLineMinutes: any;
        @Getter('getIsoLineParameters', { namespace }) getIsoLineParameters: any;
        @Getter('getIsoLineSet', { namespace }) getIsoLineSet: any;
        @Getter('getLocation', { namespace }) getLocation: any;
        @Getter('getLocationCatchmentArea', { namespace }) getLocationCatchmentArea: any;
        @Getter('getLocationDrivers', { namespace }) getLocationDrivers: any;
        @Getter('getLocations', { namespace }) getLocations: any;
        @Getter('getLocationsNotExcluded', { namespace }) getLocationsNotExcluded: any;
        @Getter('getMatchingLocations', { namespace }) getMatchingLocations: any;
        @Getter('getModel', { namespace }) getModel: any;
        @Getter('getPoiSetsVisible', { namespace }) getPoiSetsVisible: any;
        @Getter('getReadOnly', { namespace }) getReadOnly: any;
        @Getter('getScenario', { namespace }) getScenario: any;
        @Getter('getScenarioLocations', { namespace }) getScenarioLocations: any;
        @Getter('getScenarioLocationsVisible', { namespace }) getScenarioLocationsVisible: any;
        @Getter('getScenarios', { namespace }) getScenarios: any;
        @Getter('getFoundScenarioIds', { namespace }) getFoundScenarioIds: any;
        @Action('fetchAndSetScenarioChanges', { namespace }) fetchAndSetScenarioChanges: any;
        @Getter('getStandByFilterLocations', { namespace }) getStandByFilterLocations: any;
        @Getter('getStandByFilterScenarios', { namespace }) getStandByFilterScenarios: any;
        @Getter('getSubModel', { namespace }) getSubModel: any;
        @Action('searchByBoundingBox', { namespace }) searchByBoundingBox: any;
        @Action('calculateScenario', { namespace }) calculateScenario: any;
        @Action('downloadCsv') downloadCsv: any;
        @Action('downloadFile') downloadFile: any;
        @Action('editScenario', { namespace }) editScenario: any;
        @Action('fetchCatchmentArea', { namespace }) fetchCatchmentArea: any;
        @Action('fetchHeatMapPoints', { namespace }) fetchHeatMapPoints: any;
        @Action('fetchIsoLine', { namespace }) fetchIsoLine: any;
        @Action('fetchLocationIsoLines', { namespace }) fetchLocationIsoLines: any;
        @Action('fetchPois', { namespace }) fetchPois: any;
        @Action('fetchScenarioProcessingLog', { namespace }) fetchScenarioProcessingLog: any;
        @Action('filterLocations', { namespace }) filterLocations: any;
        @Action('filterScenarios', { namespace }) filterScenarios: any;
        @Action('mergeScenario', { namespace }) mergeScenario: any;
        @Action('removeLocation', { namespace }) removeLocation: any;
        @Action('removeScenario', { namespace }) removeScenario: any;
        @Action('setLocation', { namespace }) setLocation: any;
        @Action('setScenario', { namespace }) setScenario: any;
        @Action('undoCloseLocation', { namespace }) undoCloseLocation: any;
        @Action('undoEditLocation', { namespace }) undoEditLocation: any;
        @Action('findScenarios', { namespace }) findScenarios: any;
        @Mutation('SET_AREASETSVISIBLE', { namespace }) setAreaSetsVisible: any;
        @Mutation('SET_CATCHMENTAREA', { namespace }) setCatchmentArea: any;
        @Mutation('SET_FILEFORMATCULTURE') setFileFormatCulture: any;
        @Mutation('SET_HEATMAPSETSVISIBLE', { namespace }) setHeatMapSetsVisible: any;
        @Mutation('SET_ISOLINEMINUTES', { namespace }) setIsoLineMinutes: any;
        @Mutation('SET_ISOLINESET', { namespace }) setIsoLineSet: any;
        @Mutation('SET_POISETSVISIBLE', { namespace }) setPoiSetsVisible: any;
        @Mutation('SET_SCENARIOLOCATIONSVISIBLE', { namespace }) setScenarioLocationsVisible: any;
        @Mutation('SET_STANDBYFILTERLOCATIONS', { namespace }) setStandByFilterLocations: any;
        @Mutation('SET_STANDBYFILTERSCENARIOS', { namespace }) setStandByFilterScenarios: any;

        private searchLocal: any = '';
        private filterMenuVisible: boolean = false;
        private searchMenuVisible: boolean = false;
        private scenariosMenuVisible: boolean = false;

        private setScenarioAndCloseMenu(scenario: any) {
            this.setScenario(scenario);
            this.scenariosMenuVisible = false;
        }

        private get standByFilterScenarios(): any {
            return this.getStandByFilterScenarios;
        }

        private set standByFilterScenarios(value: any) {
            this.setStandByFilterScenarios(value);
        }


        private get catchmentArea(): any {
            return this.getCatchmentArea;
        }

        private set catchmentArea(value: any) {

            this.setCatchmentArea(value);
            this.fetchCatchmentArea();
        }

        private get isoLineSet(): any {
            return this.getIsoLineSet;
        }

        private set isoLineSet(value: any) {
            this.setIsoLineSet(value);
            this.fetchLocationIsoLines();
        }

        private get isoLineMinutes(): any {
            return this.getIsoLineMinutes;
        }

        private set isoLineMinutes(value: any) {
            this.setIsoLineMinutes(value);
            this.fetchLocationIsoLines();
        }

        private get scenarioLocationsVisible(): any {
            return this.getScenarioLocationsVisible;
        }

        private set scenarioLocationsVisible(value: any) {
            this.setScenarioLocationsVisible(value);
        }

        private get standByFilterLocations(): any {
            return this.getStandByFilterLocations;
        }

        private set standByFilterLocations(value: any) {
            this.setStandByFilterLocations(value);
        }

        private get areaSetsVisible(): any {
            return this.getAreaSetsVisible;
        }

        private set areaSetsVisible(value: any) {
            this.setAreaSetsVisible(value);
        }

        private get poiSetsVisible(): any {
            return this.getPoiSetsVisible;
        }

        private set poiSetsVisible(value: any) {

            this.setPoiSetsVisible(value);
            this.fetchPois();
        }

        private get heatMapSetsVisible(): any {
            return this.getHeatMapSetsVisible;

        }

        private set heatMapSetsVisible(value: any) {
            this.setHeatMapSetsVisible(value);
            this.fetchHeatMapPoints();
        }

        private get fileFormatCulture(): any {
            return this.getFileFormatCulture;
        }

        private set fileFormatCulture(value: any) {
            this.setFileFormatCulture(value);
        }

        private get noWhiteSpotAreaSets() {
            const _filteredAreaSets = this.getModel.areaSets.filter(function (areaSet: any) {
                return areaSet.isWhiteSpot == 0;
            });
            return _filteredAreaSets;
        }

        private get changedLocations() {
            if (this.getLocations != null) {
                return this.getLocations.filter(function (location: any) {
                    return location.changeType != 0;
                });
            }
            else {
                return [];
            }
        }

        private get languages() {
            return [
                { key: 'nl', text: this.$t('message.dutchFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/Eeg_BD_zaH1ArWdD59Z7awwBnLIgxFJtZ8wyAeDWzC-zHQ?e=4PQV0g' },
                { key: 'en', text: this.$t('message.englishFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/EQvrt3v6utZFsXKDDoMdbkkBpuSD3R13bVYzZduQIl2xdQ?e=tIkHF2' }
            ]
        }

        private get maxLengthLocationName() {
            let locationWithMaxName: any = null;
            let maxLength = 0;
            this.getFilteredLocations.forEach((location: any) => {
              if (location.name.length > maxLength) {
                maxLength = location.name.length;
                locationWithMaxName = location.name;
              }
            });
            return locationWithMaxName;
        }

        private get maxLengthScenarioName() {
            let scenarioWithMaxName: any = null;
            let maxLength = 0;
            this.getScenarios.forEach((scenario: any) => {
              if (scenario.name.length > maxLength) {
                maxLength = scenario.name.length;
                scenarioWithMaxName = scenario.name;
              }
            });
            return scenarioWithMaxName;
        }

        private get topScenarios() {
            var _self = this;
            return this.getScenarios.filter(function (scenario: any) {
                if (scenario.isTop && _self.getScenario.id != scenario.id) return true;
            });

        }


        private get poiSetVisible(): any {
            if (this.poiSetsVisible.length == 0) return 0; else return this.poiSetsVisible[0];
        }
        private set poiSetVisible(value: any) {
            if (value == null) this.poiSetsVisible = [0]; else this.poiSetsVisible = [value];
        }

        private get areaSetVisible(): any {
            if (this.areaSetsVisible.length == 0) return 0; else return this.areaSetsVisible[0];
        }
        private set areaSetVisible(value: any) {
            if (value == null) this.areaSetsVisible = [0]; else this.areaSetsVisible = [value];
        }

        private get heatMapSetVisible(): any {
            if (this.heatMapSetsVisible.length == 0) return 0; else return this.heatMapSetsVisible[0];
        }
        private set heatMapSetVisible(value: any) {
            if (value == null) this.heatMapSetsVisible = [0]; else this.heatMapSetsVisible = [value];
        }

        private async removeLocationLocal(location: any) {
            const res = await this.$confirm(this.$t('message.confirmRemoveLocation') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) this.removeLocation(location);
        }
        private async undoCloseLocationLocal(location: any) {
            const res = await this.$confirm(this.$t('confirmUndoCloseLocation') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) this.undoCloseLocation(location);
        }
        private async undoEditLocationLocal(location: any) {
            const res = await this.$confirm(this.$t('confirmUndoEditLocation') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) this.undoEditLocation(location);
        }
        private async mergeScenarioLocal() {
            if (this.topScenarios.length == 0) {
                await this.$confirm(this.$t('message.noTopScenariosFound') + '', { color: "error", buttonFalseText: null as any, buttonTrueText: String(this.$t('message.close')) });
            }
            else {
                this.mergeScenario({ scenarioId: this.getScenario.id, changedLocations: this.changedLocations, selectedLocations: [], deleteSourceScenario: false, destinationScenarioId: this.topScenarios[0].id });
            }
        }

        private async showMatchingLocationsOutputChart() {
            this.$emit('showMatchingLocationsOutputChart');
        }
        private async showMatchingLocationsRadarChart() {

            this.$emit('showMatchingLocationsRadarChart');
        }
        private async showDriversChart() {
            this.$emit('showDriversChart');
        }
        private async showPropertiesChart() {
            this.$emit('showPropertiesChart');
        }
        private async showLocationReport() {
            this.$emit('showLocationReport');
        }
        private async showScenarioReport() {
            this.$emit('showScenarioReport');
        }
        private async showLocationScores() {
            this.$emit('showLocationScores');
        }
        private async showAreaScores() {
            this.$emit('showAreaScores');
        }
        private async showIsoLineHelp(isoLineParameter: any) {
            if (isoLineParameter.provider == 1) window.open('https://developer.here.com/documentation/isoline-routing-api/api-reference-swagger.html', '_blank');

            if (isoLineParameter.provider == 4) window.open('https://valhalla.github.io/valhalla/api/isochrone/api-reference/', '_blank');
        }

        private selectAllPoiSets() {

            let visible = [0];
            this.getModel.poiSets.forEach(function (poiSet: any) {
                visible.push(poiSet.id);
            });
            this.poiSetsVisible = visible;
        }

        private selectNoPoiSets() {
            this.poiSetsVisible = [0];
        }

        public get isAuthenticated(): boolean {
            if (this.$msal == null) return true;
            return this.$msal.isAuthenticated;
        }

        private get scenarios(): any {
            return this.getScenarios;
        }

        private get scenario(): any {
            return this.getScenario;
        }
        private set scenario(value: any) {
            this.setScenario(value);
        }

        @Watch('getScenario')
        private onScenarioChanged() {
            
        }
        private get scenariosLeveled(): any[] {
            const nodesArray: any[] = [];
            const parentMap = new Map<number | null, any[]>();

            // Build a parent-child map and sort by isTop and name in descending order
            this.scenarios
                .sort((a: any, b: any) => {
                    if (a.isTop === b.isTop) {
                        return b.name.localeCompare(a.name); // Sort by name descending if isTop is the same
                    }
                    return a.isTop ? -1 : 1; // Sort isTop true first
                })
                .forEach((scenario: any) => {
                    const parentId = scenario.parentScenarioId ?? null;
                    if (!parentMap.has(parentId)) {
                        parentMap.set(parentId, []);
                    }
                    parentMap.get(parentId)!.push(scenario);
                });

            // Use a stack to simulate recursion
            const stack: { node: any; level: number }[] = (parentMap.get(null) || []).map((scenario: any) => ({
                node: {
                    id: scenario.id,
                    name: scenario.name,
                    scenario: scenario,
                    level: 0,
                },
                level: 0,
            }));

            while (stack.length > 0) {
                const { node, level } = stack.pop()!;
                const children = parentMap.get(node.scenario.id) || [];

                // Include the node if it has children or is in this.getFoundScenarioIds (or if this.getFoundScenarioIds is null)
                if (
                    children.length > 0 ||
                    this.getFoundScenarioIds == null ||
                    this.getFoundScenarioIds.has(node.scenario.id)
                ) {
                    nodesArray.push(node);
                }

                // Push children to the stack (sorted by isTop and name descending)
                children
                    .sort((a: any, b: any) => {
                        if (a.isTop === b.isTop) {
                            return b.name.localeCompare(a.name); // Sort by name descending
                        }
                        return a.isTop ? -1 : 1; // Sort isTop true first
                    })
                    .forEach((child: any) => {
                        stack.push({
                            node: {
                                id: child.id,
                                name: child.name,
                                scenario: child,
                                level: level + 1,
                            },
                            level: level + 1,
                        });
                    });
            }

            return nodesArray;
        }

       


        private get scenarioHasChildren() {
            var self = this;
            var found = false;
            this.getScenarios.forEach(function (childScenario: any) {
                if (childScenario.parentScenarioId == self.getScenario.id) found = true;
            });
            return found;
        }

      

        private setFilterLocations(value: any) {
            this.filterLocations(value);
        }



        private async confirmRemoveScenario() {
            const res = await this.$confirm(this.$t('message.confirmRemoveScenario') + '', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) })
            if (res) this.removeScenario(this.getScenario);
        }
    }
