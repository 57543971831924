
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    
    @Component
    export default class EditModelParameters extends Vue {
        @Prop({ default: null }) public modelParameters: any;
        @Prop({ default: null }) public model: any;

        private modalShow = false;

        private mounted() {
            this.$on('escKey' + this.$options.name, () => {
                if (this.modalShow) this.close();
            });
            document.addEventListener("keydown", (e) => {
                if (e.key === "Escape") {
                    this.$emit('escKey' + this.$options.name);
                }
            });
        }

        @Watch('modelParameters')
        private onModelParametersChanged(value: any) {
            if (value) {
                this.modalShow = true;
            }
            else {
                this.modalShow = false;
            }
        }

        private async close() {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) { this.modalShow = false }
        }

        private save() {
            this.$emit('save', this.modelParameters);
        }

        private async removeLine(row: any) {
            const res = await this.$confirm('Weet u zeker dat u deze rij wilt verwijderen?', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) })
            if (res) {
                for (let i = 0; i < this.modelParameters.functions.length; i++) {
                    if (this.modelParameters.functions[i] === row) {
                        this.modelParameters.functions.splice(i, 1);
                    }
                }
            }
        }

        private addLine() {
            this.modelParameters.functions.push({ id: -1, defaultValue: 1, type: 1});
        }

        private encode() {
            if (this.modelParameters.functions != null) {
                const f1 = this.modelParameters.functions.map(function (f: any) {
                    return { Description: f.description, Function: f.function, DefaultValue: f.defaultValue }
                });
                return f1;
            }
            else {
                return null;
            }
        }
    }
