
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    
    @Component
    export default class EditScenario extends Vue {
        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public originalScenario: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public scenarios: any;

        private modalShow = false;

        @Watch('scenario')
        private onScenarioChanged(value: any) {
            if (value) {
                this.modalShow = true;
            }
            else this.modalShow = false;
        }

        private mounted() {
            this.$on('escKey' + this.$options.name, () => {
                if (this.modalShow) this.close();
            });
            document.addEventListener("keydown", (e) => {
                if (e.key === "Escape") {
                    this.$emit('escKey' + this.$options.name);
                }
            });
        }

        private async close() 
        {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) { this.modalShow = false }
        }

        private save() {
            const valid = (this.$refs.form as any).validate();
            if (valid) this.$emit('save', this.scenario);
        }

        private nameRules = [(value: any) => !!value || this.$t("message.scenarioNameIsRequired")]

        
    }
