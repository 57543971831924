
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import PropertyValue from '@/components/main/PropertyValue.vue'
    import { convertMdiBracketToIcon } from '@/mdi';

    @Component({
        components: {
            PropertyValue,
        },
    })
    export default class Properties extends Vue {

        @Prop({ default: null }) public locationProperties: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public subModel: any;
        @Prop({ default: null }) public model: any;

        private convertMdiBracketToIcon = convertMdiBracketToIcon;

        get groupedBySectionViewOrder() {
            return this.filteredLocationProperties?.reduce((acc: any, property: any) => {
                const key = property.sectionViewOrder;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(property);
                return acc;
            }, {} as Record<number, []>);
        }
        
        get filteredLocationProperties() {
            const _self = this;
            return this.locationProperties.filter(function (locationProperty: any) {
                
                if ((locationProperty.subModelId == null || locationProperty.subModelId == _self.subModel.id) && locationProperty.visible) return (locationProperty.title != null)
                    //&& (locationProperty.propertyType != 5 || _self.location.changeType == 1 || _self.location.changeType == 0)  
                    //&& (locationProperty.propertyType != 6 || _self.location.changeType == 0 || _self.location.changeType == 2)

                    ;
            });
        }

        getChoice(choices: any, value: any) {
            let choice = null;
            choices.forEach(function (item: any) {
                if (item.key == value) choice = item.value;
            });
            if (choice == null) choice = value;
            return choice;
        }

    }
