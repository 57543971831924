
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { removeMdiBracketToIcon } from '@/mdi';

    @Component
    export default class EditLocation extends Vue {
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public scenario: any;

        private removeMdiBracketToIcon = removeMdiBracketToIcon;
        private modalShow = false;
        private valid = false;
        private menu = false;
        private nameRules: (any)[] = [(v: string) => !!v || this.$t('message.nameRequired')];
        
        private mounted() {
            this.$on('escKey' + this.$options.name, () => {
                if (this.modalShow) this.close();
            });
            document.addEventListener("keydown", (e) => {
                if (e.key === "Escape") {
                    this.$emit('escKey' + this.$options.name);
                }
            });
        }

        private changed() {
            this.$emit('changed', this.location?.locationProperties);
        }
        
        @Watch('location')
        private onLocationChanged(value: any) {
            if (value) this.modalShow = true; else this.modalShow = false;
            if (this.location != null) {

                this.location.locationProperties.forEach(function (locationProperty: any) {
                    if (locationProperty.dataType == 2) {
                        locationProperty["percentageValue"] = locationProperty.numberValue * 100;
                    }
                });
            }
            this.changed();
        }

        private save(scenarioCreation: any) {
            const fieldsToValidate = ['locationname']; // List of your ref names
            (this.$refs.form as any).validate()
            fieldsToValidate.forEach((fieldRef) => {
            const field = this.$refs[fieldRef] as any;
                if (field && field.validate) {
                    const isValid = field.validate();
                    if (!isValid) field.$el.scrollIntoView({ behavior: 'smooth' });
                }
            });
            if (this.valid) {
                this.location['scenarioCreation'] = scenarioCreation;
                this.location.locationProperties.forEach(function (locationProperty: any) {
                    if (locationProperty.dataType == 2) {
                        locationProperty.numberValue = locationProperty.percentageValue / 100;
                    }
                });
                this.$emit('save', this.location );
            }
        }

        private async close() {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) { this.modalShow = false }
        }

        private toggleMenu() {
            this.menu = !this.menu;
        }

        private groupBy(list :any, keyGetter:any) {
            const map = new Map();
            list.forEach((item: any) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        }
        
        private get locationPropertiesBySection() {
            return this.groupBy(this.location.locationProperties, function (locationProperty: any) { return locationProperty.sectionTitle } );
        }

        private get selectableLocationGroups() {
            const _self = this;
            return this.model.locationGroups.filter(function (locationGroup: any) {
                return locationGroup.selectable || _self.location.locationGroup == locationGroup;
            });
        }
    }
