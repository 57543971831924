
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class PropertyValue extends Vue {
        @Prop({ default: false }) public output: any;
        @Prop({ default: false }) public onlyCurrentValue: any;
        @Prop({ default: false }) public onlyImpact: any;
        @Prop({ default: null }) public property: any;
        @Prop({ default: 'EUR' }) public currency: any;
        
        getChoice(choices: any, value: any) {
            if (choices) {
                let choice = null;
                choices.forEach(function (item: any) {
                    if (item.key == value) choice = item.value;
                });
                if (choice == null) choice = value;
                return choice;
            }
            return null;
        }

        getRangeColor(rangeColor: any, value: any): string {
            // Ensure the value is within bounds
            const valueLocal = Math.max(rangeColor.minimumValue, Math.min(rangeColor.maximumValue, value));
            // Calculate the ratio of the value within the range
            const ratio = (valueLocal - rangeColor.minimumValue) / (rangeColor.maximumValue - rangeColor.minimumValue);
            // Convert hex colors to RGB
            const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
                const bigint = parseInt(hex.slice(1), 16);
                return {
                    r: (bigint >> 16) & 255,
                    g: (bigint >> 8) & 255,
                    b: bigint & 255,
                };
            };
            const minRgb = hexToRgb(rangeColor.minimumColor);
            const maxRgb = hexToRgb(rangeColor.maximumColor);
            // Interpolate between minColor and maxColor
            const r = Math.round(minRgb.r + ratio * (maxRgb.r - minRgb.r));
            const g = Math.round(minRgb.g + ratio * (maxRgb.g - minRgb.g));
            const b = Math.round(minRgb.b + ratio * (maxRgb.b - minRgb.b));
            // Convert back to hex
            const rgbToHex = (r: number, g: number, b: number): string =>
                `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
            return rgbToHex(r, g, b);
        }
    }
