import Vue from 'vue'
Vue.nextTick();
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
// @ts-ignore
import Fragment from 'vue-fragment'
import VueI18n from 'vue-i18n'
// @ts-ignore
import messages from './messages'
import VueRouter from 'vue-router'
import LoginMenu from './components/LoginMenu.vue'
import ModelMenu from './components/main/ModelMenu.vue'
import ModelLogo from './components/main/ModelLogo.vue'
import NotFound from './components/NotFound.vue'
import BearerToken from './components/BearerToken.vue'
import Main from './components/main/Main.vue'
import AdminMain from './components/admin/Main.vue'
import TopLeftMenu from './components/main/TopLeftMenu.vue'
import AddLogin from './components/admin/AddLogin.vue'
import UsersList from '@/components/admin/UsersList.vue';
import LoginsList from '@/components/admin/LoginsList.vue';
import User from '@/components/admin/User.vue';
import ScenariosList from '@/components/admin/ScenariosList.vue';
import Scenario from '@/components/admin/Scenario.vue';
import ModelsList from '@/components/admin/ModelsList.vue';
import Model from '@/components/admin/Model.vue';
import AdminTopLeftMenu from './components/admin/TopLeftMenu.vue'
import VuetifyConfirm from 'vuetify-confirm'

Vue.use(VuetifyConfirm, {
    vuetify,
    buttonTrueText: 'Ja',
    buttonFalseText: 'Nee',
    //color: 'warning',
    color: null,
    icon: '',
    //title: 'Waarschuwing',
    width: 350,
    property: '$confirm'
})

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, LineController } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, LineController)
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels);



Vue.prototype.Window = window;

Vue.use(Fragment.Plugin);
Vue.use(VueI18n);
Vue.use(VueRouter);

const i18n = new VueI18n({
    locale: 'nl', // set locale
    messages, // set locale messages
})

const defaultRoute = {
    path: '/',
    name: 'Default',
    components: {
        default: Main,
        loginMenu: LoginMenu,
        modelMenu: ModelMenu,
        modelLogo: ModelLogo,
        topLeftMenu: TopLeftMenu,

    }
}



const adminRoute = {
    path: '/admin',
    name: 'Admin',
    components: {
        default: AdminMain,
        loginMenu: LoginMenu,
      

        topLeftMenu: AdminTopLeftMenu
    }
}

const adminUsersRoute = {
    path: '/admin/users',
    name: 'AdminUsers',
    components: {
        default: AdminMain,
        adminLeft: UsersList,
        adminRight: User,
        loginMenu: LoginMenu,
        topLeftMenu: AdminTopLeftMenu
    }
}


const adminLoginsRoute = {
    path: '/admin/logins',
    name: 'AdminLogins',
    components: {
        default: AdminMain,
        adminLeft: LoginsList,
        adminRight: AddLogin,
        loginMenu: LoginMenu,
        topLeftMenu: AdminTopLeftMenu
    }
}

const adminModelsRoute = {
    path: '/admin/models',
    name: 'AdminModels',
    components: {
        default: AdminMain,
        adminLeft: ModelsList,
        adminRight: Model,
        loginMenu: LoginMenu,
        topLeftMenu: AdminTopLeftMenu
    }
}


const adminScenarioStatusRoute = {
    path: '/admin/scenarioStatus',
    name: 'ScenarioStatus',
    components: {
        default: AdminMain,
        adminLeft: ScenariosList,
        adminRight: Scenario,
        loginMenu: LoginMenu,
        topLeftMenu: AdminTopLeftMenu
    }
}

const notFoundRoute = {
    path: '*',
    components: {
        default: NotFound,
    }
}

export const router = new VueRouter({
    mode: 'history',
    routes: [
        defaultRoute, adminRoute, adminUsersRoute, adminModelsRoute, adminLoginsRoute, adminScenarioStatusRoute,notFoundRoute
    ]
})


import dateFilter from '@/filters/date.filter';
import timeFilter from '@/filters/time.filter';
import datetimeFilter from '@/filters/datetime.filter';
import currencyFilter from '@/filters/currency.filter';
import roundingFilter from '@/filters/rounding.filter';
import fixedNumberFilter from '@/filters/fixedNumber.filter';
import percentageFilter from '@/filters/percentage.filter';

Vue.filter('date', dateFilter);
Vue.filter('time', timeFilter);
Vue.filter('datetime', datetimeFilter);
Vue.filter('currency', currencyFilter);
Vue.filter('percentage', percentageFilter);
Vue.filter('rounding', roundingFilter);
Vue.filter('fixed', fixedNumberFilter);

Vue.config.productionTip = false


export default i18n;

new Vue({
    router,
    i18n,
    vuetify,
    store,
    
    render: h => h(App)
}).$mount('#app')
