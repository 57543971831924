
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import Properties from '@/components/main/Properties.vue'

    @Component({
        components: {
            Properties
        },
    })
    export default class LocationInformation extends Vue {
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public subModel: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public locationProperties: any;
        @Prop({ default: null }) public locationPropertiesOld: any;
        
        private selectViewLocation(value: any) {
            return value.viewLocation == 0;
        }
    }
