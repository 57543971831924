export function convertMdiBracketToIcon(input : string) {
    return input.replace(/\[([a-z-]+)\]/g, (match, iconName) => {
        return `<i aria-hidden="true" style="color: rgba(0, 0, 0, 0.54)" class="mdi mdi-${iconName}"></i>`;
    });
}

export function removeMdiBracketToIcon(input: string) {
    return input.replace(/\[([a-z-]+)\]/g, (match, iconName) => {
        return ``;
    });
}
