
    import { Component, Vue } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';

    const namespace = 'main';

    @Component
    export default class TopLeftMenu extends Vue {
        private namespace = 'main';

        @Getter('getModel', { namespace }) getModel: any;
        @Getter('getLocation', { namespace }) getLocation: any;
        @Action('fetchModelParameters', { namespace }) fetchModelParameters: any;
        @Action('fetchProcessingLog', { namespace }) fetchProcessingLog: any;
        @Action('fetchNormalizedScoresFunctions', { namespace }) fetchNormalizedScoresFunctions: any;
        @Action('fetchDrivers', { namespace }) fetchDrivers: any;
        @Action('fetchLog', { namespace }) fetchLog: any;
        @Getter('getScenario', { namespace }) getScenario: any;
        @Getter('getArea', { namespace }) getArea: any;
        @Getter('getVersion') getVersion: any;

        private router: any;
        private mounted() {
            this.router = this.$router;
        }
        
    }
