const messages = {
    nl: {
        message: {
            selectedScenario: 'Geselecteerde scenario',
            submodel: 'Submodel',
            confirmCalculateScenario: 'Het scenario is gewijzigd. Wilt u het scenario doorrekenen?',
            search: 'Zoeken op de kaart',
            scenarioNameAutomaticallyChangedNotificaton: 'De naam van het scenario is automatisch aangepast omdat er nog maar 1 wijziging was.',
            scenarioDeletedBecauseEmptyNotificaton: 'Het scenario had geen wijzigingen meer en is daarom verwijderd.',
            setScenarioNameMessage: 'Omdat je een meervoudig scenario maakt, is het verplicht de naam van het scenario op te geven.',
            setScenarioName: 'Scenario naam',
            saveAddToNewScenario: 'Opslaan (in nieuw scenario)',
            saveAddToThisScenario: 'Opslaan (in huidige scenario)',
            saveAddToNewNestedScenario: 'Opslaan (in nieuw genest scenario)',
            saveEditInNewScenario: 'Opslaan (in nieuw scenario)',
            saveEditInThisScenario: 'Opslaan (in huidige scenario)',
            saveEditInNewNestedScenario: 'Opslaan (in nieuw genest scenario)',
            cancel: 'Annuleren',
            closeMessage: 'Je staat op het punt de locatie te sluiten.',
            closeLocation: 'Locatie sluiten',
            closeLocationInNewScenario: 'Locatie sluiten (in nieuw scenario)',
            closeLocationInThisScenario: 'Locatie sluiten (in huidig scenario)',
            closeLocationInNewNestedScenario: 'Locatie sluiten (in nieuw genest scenario)',
            calculateThisScenario: "Doorrekenen huidige scenario",


            nameRequired: 'Naam is verplicht',
            scenarioLocationsVisibleOnMap: 'Locaties van scenarios',
            scenarios: 'Scenarios',
            errorOccured: 'Nou, er is iets misgegaan. Er heeft zich een onverwachte fout voorgedaan, mogelijk als gevolg van een slechte internetverbinding. Sluit dit bericht en probeer de actie opnieuw. Als het probleem aanhoudt, vernieuw dan de pagina. Blijft de fout zich voordoen, controleer dan je internetverbinding of neem contact op met de ondersteuning voor hulp.',
            noTopScenariosFound: 'Er zijn geen top scenarios gevonden. Maak een nieuwe top scenario aan of pas een bestaand scenario aan zodat deze top scenario is.',
            filterScenarios: 'Zoeken naar/binnen scenarios',
            locationInDefaultScenario: 'U kunt het default scenario niet aanpassen. Als u doorgaat wordt de locatie aangemaakt in een nieuw scenario met dezelfde naam als de locatie. Wilt u doorgaan?',
            generatedOn: 'Gegenereerd op',
            foundLocations: 'Gevonden locaties',
            of: 'van',
            page: 'Pagina',
            selectAll: 'alles',
            selectNone: 'geen',
            heatMaps: 'Warmtekaart',
            print: 'Afdrukken',
            no: 'Nee',
            yes: 'Ja',
            copyChangesInformation: 'Hieronder kunt u aangeven welke locaties worden gekopieerd naar het doel scenario.',
            locations: 'Locaties',
            copyChanges: 'Wijzigingen kopiëren',
            nonShared: 'Niet gedeeld',
            sharedWith: 'Gedeeld met',
            otherLocations: 'andere locaties',
            mergeScenarioSource: 'De volgende wijzigingen zullen worden samengevoegd in het opgegeven scenario.',
            targetScenario: 'Doel scenario',
            deleteSourceScenario: 'Verwijder het bron scenario',
            isTopScenarioDescription: 'Een top scenario zorgt er voor dat het scenario als eerste en vet gedrukt wordt weergegeven in de lijst met scenarios.',
            mergeScenario: 'Scenario samenvoegen',
            isTopScenario: 'Top scenario',
            confirmClose: 'Weet u zeker dat u dit scherm wilt sluiten?',
            displayName: 'Weergave naam',
            email: 'E-mail adres',
            mobilePhone: 'Mobiel telefoonnummer',
            calculationModel: 'Calculation model',
            calculateUnprocessedScenarios: 'Doorrekenen onverwerkte scenarios',
            log: 'Log',
            warning: 'Waarschuwing',
            eventType: 'Type',
            source: 'Bron',
            message: 'Bericht',
            fetchIsoLine: 'Isolijn ophalen',
            isoLine: 'Isolijn',
            scheduledDateTimeUtc: 'Gepland',
            isoLinesMissingDateTimeUtc: 'Missende rijtijden',
            startedDateTimeUtc: 'Start',
            endedDateTimeUtc: 'Einde',
            noMatchingLocations: 'Geen overeenkomstige locaties',
            scenarioNameIsRequired: 'Scenario naam is verplicht',
            parentScenario: 'Bron scenario',
            sourceLocations: 'Bron locaties',
            sourceLocationsAllScenarios: 'Bron locaties alle scenarios',
            driverFilterFunction: 'Functie',
            driverFilterTitle: 'Titel',
            addDriver: 'Verklaring toevoegen',
            addDriverFilter: 'Groep toevoegen',
            confirmRemoveScenario: 'Weet u zeker dat u dit scenario wilt verwijderen?',
            confirmRemoveLocation: 'Weet u zeker dat u deze locatie wilt verwijderen?',
            confirmUndoCloseLocation: 'Weet u zeker dat u de sluiting van deze locatie ongedaan wilt maken?',
            confirmUndoEditLocation: 'Weet u zeker dat u de bewerkingen van deze locatie ongedaan wilt maken?',
            editScenario: 'Bewerken',
            removeScenario: 'Verwijderen',
            calculateScenario: 'Doorrekenen',
            filterAndSearch: 'Locaties',
            map: 'Kaart',
            matchingPercentage: '% Overeenkomst',
            drawerNotCalculated: 'Het model is niet doorgerekend. Daardoor zijn de resultaten niet zichtbaar.',
            drawerCalculating: 'Het model is aan het rekenen. Wacht tot de resultaten zichtbaar worden.',
            legend: 'Legenda',
            modelAdmin: 'Model beheerder',
            globalAdmin: 'Hoofd beheerder',
            noChanges: 'Er zijn nog geen openingen of sluitingen.',
            name: 'Naam',
            query: 'Zoek-tekst',
            export: 'Exporteer',
            noDrivers: 'Er zijn geen verklaringen gedefinieerd voor dit model.',
            condition: 'Voorwaarde',
            roundingDigits: 'Decimalen',
            inverse: 'Achterstevoren',
            drivers: 'Verklaringen',
            report: 'rapport',
            matchingLocationsOutputChart: 'Resultaten diagram',
            matchingLocationsSimilarityChart: 'Overeenkomsten diagram',
            driversChart: 'Verklaring diagram',
            disclaimer: 'Disclaimer',
            disclaimerContent: 'De ontwikkeling van IRIS is gebaseerd op wetenschappelijke methoden, actuele verkooppunt informatie en zorgvuldig onderzochte en up-to-date klantinformatie. Het model wordt zo goed mogelijk gevalideerd op basis van de actuele omzetten die in de markt worden gerealiseerd en informatie over het functioneren van de markt. Ieder wiskundig model, hoe gedetailleerd ook in de uitwerking, is een vereenvoudigde weergave van de werkelijkheid en zal nooit 100% correct zijn. Aan de uitkomsten kunnen daarom geen rechten worden ontleend.',
            help: 'Handleiding',
            csvExport: 'Export data',
            fileFormatCulture: 'CSV taalinstelling',
            dutchFormat: 'Nederlands formaat',
            englishFormat: 'Engels formaat',
            areaInformation: 'Gebiedsinformatie',
            areaInformationCatchmentArea: 'Gebiedsinformatie verzorgingsgebied',
            adminPages: 'Admin',
            locationAreas: 'Gebieden van de locatie',
            adminOnly: 'Alleen voor beheerders (WERKT NOG NIET)',
            scenarioCompetition: 'Locaties in dit scenario concureren met elkaar.',
            scenarioProcessingLog: 'Verwerkingslogboek',
            calculationScheduled: 'Verwerking ingepland',
            calculationWaitingForIsolines: 'Verwerking wacht op isochronen',
            calculationStarted: 'Verwerking start',
            calculationEnded:'Verwerking einde',
            calculationScenarioError: 'Verwerking fout (scenario)',
            calculationModelError: 'Verwerking fout (model)',
            isolinesStarted: 'Isochronen ophalen start',
            isolinesEnded: 'Isochronen ophalen einde',
            isolinesError: 'Isochronen ophalen fout',
            isolinesBusy: 'Isochronen ophalen bezig',
            dateAndTime: 'Datum en tijd',
            action: 'Actie',
            model: 'Model',
            user: 'Gebruiker',
            last100records: 'Laatste 100 records',
            propertiesChart: 'XY grafiek',
            propertyX: 'Eigenschap op X as',
            propertyY: 'Eigenschap op Y as',
            tree: 'boom | bomen',
            applicationTitle: 'IRIS',
            title: 'Titel',
            logout: 'Afmelden',
            login: 'Aanmelden',
            share: 'Aandeel',
            area: 'Gebied | Gebieden',
            personType: 'Persona',
            inhabitants: 'Inwoners',
            houseHolds: 'Huishoudens',
            income: 'Inkomen',
            areaRevenuePerYear: 'Besteding in postcode per jaar',
            areaRevenuePerPeriod: 'Besteding in postcode per periode',
            areaStandardConsumers: 'Standaard consumenten in postcode',
            total: 'Totaal',
            travelTimeScores: 'Aantrekkelijkheden reistijd',
            from: 'vanaf',
            calculation: 'Berekening',
            perYear: 'Per jaar',
            catchmentArea: 'Verzorgingsgebied',
            outputSpending: 'Besteding',
            outputNumber: 'Aantal',
            car: 'Auto',
            pedestrian: 'Voetganger',
            scorePersonType: 'Aantrekkelijkheid persona',
            calculated: 'Berekend',
            location: 'Locatie',
            value: 'Waarde',
            defaultValue: 'Standaard waarde',
            expression: 'Functie',
            format: 'Formule',
            scoresLocationGroup: 'Aantrekkelijkheden formule',
            scoresShop: 'Aantrekkelijkheden winkel',
            scoresRetailArea: 'Aantrekkelijkheden winkelgebied',
            retailArea: 'Winkelgebied',
            changedLocations: 'Wijzigingen',
            opening: 'Opening',
            closure: 'Sluiting',
            edited: "Aangepast",
            edit: "Bewerk",
            addScenario: 'Scenario toevoegen',
            infoScenario: 'Scenario informatie',
            calculate: 'Doorrekenen',
            add: 'Toevoegen',
            info: 'Informatie',
            scenarioCreated: 'Aanmaakdatum',
            scenarioModified: 'Bewerkdatum',
            scenarioCreatedBy: 'Aangemaakt door',
            scenarioModifiedBy: 'Bewerkt door',
            scenario: 'Scenario',
            scenarioReport: 'Impact rapport',
            scenarioName: 'Scenario naam',
            save: 'Opslaan',
            error: 'Fout',
            url: 'URL',
            status: 'Status',
            description: 'Omschrijving',
            settings: 'Instellingen',
            new: 'Nieuw',
            modelParameters: 'Model parameters',
            none: 'Geen',
            editModelParameters: 'Bewerk model parameters',
            editDrivers: 'Bewerk verklaringen',
            propertyType: 'Type',
            drawerEmptyText: 'Klik op een locatie op de kaart om informatie weer te geven.',
            score: 'Aantrekkelijkheid | Aantrekkelijkheden',
            information: 'Informatie',
            graphics: 'Grafieken',
            layers: 'Lagen',
            all: 'Alles',
            primary: 'Primair (50%)',
            secondary: 'Secundair (80%)',
            impact: 'Impact',
            impactCar: 'Impact auto',
            impactPedestrian: 'Impact voetganger',
            display: 'Weergave',
            top10: 'Top 10',
            top25: 'Top 25',
            top: 'Top',
            fraction: 'Aandeel',
            fractionCumulative: 'Aandeel (cumulatief)',
            showTravelTimes: 'Reistijd weergeven',
            travelTimes: 'Reistijd',
            minutes: 'Minuten',
            group: 'Group',
            property: 'Eigenschap',
            expressions: 'Formules',
            powerFactor: 'Machtsfactor',
            constant: 'Constante',
            scale: 'Schaal',
            max: 'Maximum',
            by: "door",
            fractionCutOffThreshold: 'Fractie drempel waarde',
            differenceDefaultScenario: 'Verschil t.o.v. standaard scenario',
            reports: 'Rapportage',
            locationsType1Name: 'Vestiging | Vestigingen',
            locationsType2Name: 'Winkelgebied | Winkelgebieden',
            locationsType1FilterLocations: 'Filter op vestiging van het scenario',
            locationsType2FilterLocations: 'Filter op winkelgebied van het scenario',
            googleSearch: 'Zoeken op de kaart',
            locationType1Scores: 'Aantrekkelijkheden vestiging',
            locationType2Scores: 'Aantrekkelijkheden winkelgebied',
            areaScores: 'Aantrekkelijkheden reistijd',
            locationsType1Impact: 'Impact per vestiging (top 25)',
            locationsType2Impact: 'Impact per winkelgebied (top 25)',
            locationGroupsType1Name: 'Formule',
            locationGroupsType2Name: 'Winkelgebied soort',
            locationsGroupsType1Impact: 'Impact per formule (top 10)',
            locationsGroupsType2Impact: 'Impact per winkelgebied soort (top 10)',
            locationsClustersType1Impact: 'Impact per winkelgebied (top 10)',
            locationsClustersType2Impact: 'Impact per gemeente (top 10)',
            locationsClustersType1EmptyName: '',
            locationsClustersType2EmptyName: '',
            locationsGroupsType1EmptyName: 'Zelfstandig',
            locationsGroupsType2EmptyName: '',
            locationClustersType1Name: 'Winkelgebied',
            locationClustersType2Name: 'Gemeente',
            outputType1Name: "Omzet per week",
            outputType2Name: "Standaard consumenten",
            outputType3Name: "Omzet per jaar",
            outputType4Name: "Standaard consumenten",
            areasType1Name: "Postcode | Postcodes",
            areasType2Name: "Statistische sector | Statisische sectoren",
            locationsType1New: "Nieuwe vestiging hier",
            locationsType2New: "Nieuwe winkelgebied hier",
            close: "Sluiten",
            delete: "Verwijderen",
            undoClose: "Sluiten ongedaan maken",
            undoEdit: "Bewerking ongedaan maken",
            modelFunctionTypeToUse: "Het is mogelijk de volgende velden te gebruiken:",
            ncalcMessage: "De functies maken gebruik van de NCalc bibliotheek. Kijk op https://github.com/ncalc/ncalc voor meer informatie.",
            calculationModelOutput: "Model resultaten",
            locationsOutput: 'Locations',
            scenarioLocationsOutput: 'Resultaten',
            scenarioLocations: 'Locaties',
            scenarioLocationsAllScenarios: 'Locaties van alle scenarios',
            scenarioLocationsOutputDescription: 'Resultaten van het geselecteerde scenario',
            scenarioLocationsOutputDescriptionWithHidden: 'Resultaten van het geselecteerde scenario alle velden',
            locationsOutputDescription: 'Locations.csv',
            catchmentAreaInformationExport: "Verzorgingsgebied van de geselecteerde locatie",
            areaInformationExport: "Gebied resultaat",
            areaInformationExportDescription: "Gebied resultaat van het geselecteerde gebied",
            weight: "Gewicht",
            editNormalizedScoresFunctions: "Bewerk functies genormaliseerde waarden",
            normalizedScoresFunctions: "Functies genormaliseerde waarden",
            before: "Voor",
            after: "Na",
            difference: "Verschil",
            localSituation: "Lokale situatie",
            dontShow: "Niet weergeven",
            pois: "POI's",
            matchingLocations: "Vergelijkbare locaties",
            scenarioStatusEdited: "Bewerkt",
            scenarioStatusWaiting: "Wachten op verwerking",
            scenarioStatusProcessing: "Bezig met verwerken",
            scenarioStatusCompleted: "Verwerkt",
            scenarioStatusCompletedWithErrors: "Verwerkt met fouten",
            pleaseLogin: "Meld u aan via de knop aan de rechterbovenkant.",
            busyLoading: "Bezig met laden...",
            searchMap: "Zoeken op de kaart",
            
        }
    },
    en: {
        message: {
            submodel: 'Submodel',
            confirmCalculateScenario: 'The scenario has changed. Do you want to calculate the scenario?',
            search: 'Search on the map',
            scenarioNameAutomaticallyChangedNotificaton: 'The name of the scenario is automatically changed because there was only 1 change left.',
            scenarioDeletedBecauseEmptyNotificaton: 'The scenario did not have any changes left so is deleted.',
            calculateThisScenario: "Calculate this scenario",
            setScenarioNameMessage: 'Since you are creating a multiple scenario, if it required to specify the name of the scenario.',
            setScenarioName: 'Scenario name',
            saveAddToNewScenario: 'Save (in new scenario)',
            saveEditInNewScenario: 'Save (in new scenario)',
            saveAddToThisScenario: 'Save (in current scenario)',
            saveEditInThisScenario: 'Save (in current scenario)',
            saveAddToNewNestedScenario: 'Save (in new nested scenario)',
            saveEditInNewNestedScenario: 'Save (in new nested scenario)',
            cancel: 'Cancel',
            closeMessage: 'You are about to close the selected location.',
            closeLocation: 'Close location',
            closeLocationInNewScenario: 'Close location (in new scenario)',
            closeLocationInThisScenario: 'Close location (in current scenario)',
            closeLocationInNewNestedScenario: 'Close location (in new nested scenario)',
            selectedScenario: 'Selected scenario',

            nameRequired: 'Name is required',
            scenarioLocationsVisibleOnMap: 'Locations of scenarios',
            scenarios: 'Scenarios',
            errorOccured: 'Oops! Something went wrong. An unexpected error has occurred, possibly due to a poor internet connection. Please close this message and attempt the action again. If the issue persists, kindly refresh the page. If the problem continues, consider checking your internet connection or contacting support for assistance.',
            noTopScenariosFound: 'There are no top scenarios found. Create a new top scenario or edit an existing scenario to be a top scenario.',
            filterScenarios: 'Search on/within scenarios',
            locationInDefaultScenario: 'You cannot modify the default scenario. If you continue the location will be created in a new scenario with the same name as the location. Do you want to continue?',
            foundLocations: 'Found locations',
            generatedOn: 'Generated on',
            of: 'of',
            page: 'Page',
            selectAll: 'all',
            selectNone: 'none',
            heatMaps: 'Heat maps',
            print: 'Print',
            no: 'No',
            yes: 'Yes',
            copyChangesInformation: 'Please specify which changes you want to copy to the target scenario.',
            locations:'Locations',
            copyChanges: 'Copy changes',
            nonShared: 'Non shared',
            sharedWith: 'Shared with',
            otherLocations: 'other locations',
            mergeScenarioSource: 'The following changes will be merged into the selected scenario.',
            targetScenario: 'Target scenario',
            deleteSourceScenario: 'Delete source scenario',
            isTopScenarioDescription: 'A top scenario specifies the scenario is listed first in the list of scenarios and is highlighted bold.',
            isTopScenario: 'Top scenario',
            mergeScenario: 'Merge scenario',
            confirmClose: 'Are you sure you want to close this dialog?',
            displayName: 'Displayname',
            email: 'E-mail address',
            mobilePhone: 'Mobile phone',
            calculationModel: 'Calculation model',
            calculateUnprocessedScenarios: 'Process unprocessed scenarios',
            log: 'Log',
            eventType: 'Type',
            source: 'Source',
            message: 'Message',
            warning: 'Warning',
            fetchIsoLine: 'Fetch isoline',
            isoLine: 'Isoline',
            scheduledDateTimeUtc: 'Scheduled',
            isoLinesMissingDateTimeUtc: 'Missing drive times',
            startedDateTimeUtc: 'Start',
            endedDateTimeUtc: 'End',
            noMatchingLocations: 'No matching locations',
            scenarioNameIsRequired: 'Scenario name is required',
            parentScenario: 'Parent scenario',
            sourceLocations: 'Source locations',
            sourceLocationsAllScenarios: 'Source locations all scenarios',
            driverFilterFunction: 'Function',
            driverFilterTitle: 'Title',
            addDriver: 'Add driver',
            addDriverFilter: 'Add group',
            confirmRemoveScenario: 'Are you sure you want to delete this scenario?',
            confirmRemoveLocation: 'Are you sure you want to delete this location?',
            confirmUndoCloseLocation: 'Are you sure you want to undo the closing of this location?',
            confirmUndoEditLocation: 'Are you sure you want to undo the editing of this location?',
            editScenario: 'Edit',
            removeScenario: 'Remove',
            calculateScenario: 'Calculate',

            filterAndSearch: 'Locations',
            map: 'Map',
            matchingPercentage: 'Matching %',
            drawerNotCalculated: 'The model is not calculated. Model results are unavailable.',
            drawerCalculating: 'The model is calculating. Wait for the results to be available.',
            legend: 'Legend',
            modelAdmin: 'Model administrator',
            globalAdmin: 'Global administrator',
            noChanges: 'The are no openings or closures.',
            name: 'Name',
            query: 'Search text',
            export: 'Export',
            noDrivers: 'There are no drivers defined for this model.',
            condition: 'Condition',
            roundingDigits: 'Decimals',
            inverse: 'Inverse',
            drivers: 'Drivers',
            report: 'report',
            matchingLocationsOutputChart: 'Output diagram',
            matchingLocationsSimilarityChart: 'Radar diagram',
            driversChart: 'Drivers diagram',
            disclaimer :'Disclaimer',
            disclaimerContent: 'IRIS draws on the latest scientific methods, current point of sale information, market data, and consumer insights. Our predictive models are estimated with, and validated against, historical revenue data. Any predictive model, no matter how advanced, is a simplified representation of reality and thus will never achieve 100% accuracy. No rights can be derived from the model outputs.',
            help: 'Manual',
            csvExport: 'Export data',
            fileFormatCulture: 'CSV language settings',
            dutchFormat: 'Dutch format',
            englishFormat: 'English format',
            areaInformation: 'Area information',
            areaInformationCatchmentArea: 'Area information catchment area',
            locationAreas: 'Areas of the location',
            adminPages: 'Admin',
            graphics: 'Charts',
            adminOnly: 'Only for administrators',
            scenarioCompetition: 'Locations created in this scenario compete with eachother.',
            scenarioProcessingLog: 'Processing log',
            calculationScheduled: 'Processing scheduled',
            calculationWaitingForIsolines: 'Processing waiting for isolines',
            calculationStarted: 'Processing started',
            calculationEnded: 'Processing ended',
            calculationScenarioError: 'Processing error (scenario)',
            calculationModelError: 'Processing error (model)',
            isolinesStarted: 'Isolines retrieval started',
            isolinesEnded: 'Isolines retrieval ended',
            isolinesError: 'Isolines retrieval error',
            isolinesBusy: 'Isolines retrieval busy',
            dateAndTime: 'Date and time',
            action: 'Action',
            model: 'Model',
            user: 'User',
            last100records: 'Last 100 records',
            propertiesChart: 'XY chart',
            propertyX: 'Property on X axis',
            propertyY: 'Property on Y axis',
            applicationTitle: 'IRIS',
            title: 'Title',
            logout: 'Logout',
            login:'Login',
            share: 'Share',
            area: 'Zipcode | Zipcodes',
            personType: 'Person type',
            inhabitants: 'Inhabitants',
            houseHolds: 'Housholds',
            income: 'Income',
            areaRevenuePerYear: 'Expense in zipcode per year',
            areaRevenuePerPeriod: 'Expense in zipcode per period',
            areaStandardConsumers: 'Standard consumers in zipcode',
            total: 'Total',
            travelTimeScores: 'Attractions travel time',
            from: 'from',
            calculation: 'Calculation',
            perYear: 'Per year',
            catchmentArea: 'Catchment area',
            outputSpending: 'Expense',
            outputNumber: 'Number',
            car: 'Car',
            pedestrian: 'Pedestrian',
            scorePersonType: 'Attraction person type',
            calculated: 'Calculated',
            location: 'Location',
            value: 'Value',
            defaultValue: 'Default value',
            expression: 'Function',
            format: 'Format',
            scoresLocationGroup: 'Attractions format',
            scoresShop: 'Attractions shop',
            scoresRetailArea: 'Attractions retail area',
            retailArea: 'Retail area',
            changedLocations: 'Changes',
            opening: 'Opening',
            closure: 'Closure',
            add:'Add',
            edit: "Edit",
            edited: "Edited",
            addScenario: 'Add scenario',
            infoScenario: 'Scenario information',
            info:'Information',
            calculate: 'Calculate',
            scenarioCreated: 'Creation date',
            scenarioModified: 'Edit date',
            scenarioCreatedBy: 'Created by',
            scenarioModifiedBy: 'Edited by',
            scenario: 'Scenario',
            scenarioName: 'Scenario name',
            save: 'Save',
            error: 'Error',
            url: 'URL',
            status: 'Status',
            description: 'Description',
            settings: 'Settings',
            new: 'New',
            modelParameters: 'Model parameters',
            scenarioReport: 'Impact report',
            editModelParameters: 'Edit model parameters',
            editDrivers: 'Edit drivers',
            propertyType: 'Type',
            drawerEmptyText: 'Click a location on the map to display information.',
            score: 'Attraction | Attractions',
            information: 'Information',
            layers: 'Layers',
            all: 'Everything',
            primary: 'Primary (50%)',
            secondary: 'Secondary (80%)',
            impact: 'Impact',
            impactCar: 'Impact car',
            impactPedestrian: 'Impact pedestrian',
            display: 'Display',
            top10: 'Top 10',
            top25: 'Top 25',
            top: 'Top',
            fraction: 'Share',
            fractionCumulative: 'Share (cumulative)',
            showTravelTimes: 'Show travel times',
            travelTimes: 'Travel times',
            minutes: 'Minutes',
            group: 'Group',
            property: 'Property',
            expressions: 'Expressions',
            powerFactor: 'Power factor',
            constant: 'Constant',
            scale: 'Scale',
            max: 'Maximum',
            fractionCutOffThreshold: 'Fraction cut off threshold',
            differenceDefaultScenario: 'Difference default scenario',
            reports: 'Reports',
            none: 'None',
            locationsType1Name: 'Location | Locations',
            locationsType2Name: 'Retail area | Retail areas',
            locationsType1FilterLocations: 'Filter on location of the scenario',
            locationsType2FilterLocations: 'Filter on retail area of the scenario',
            googleSearch: 'Search the map',
            locationType1Scores: 'Attractions location',
            locationType2Scores: 'Attractions retail area',
            areaScores: 'Attractions travel times',
            locationsType1Impact: 'Impact per location (top 25)',
            locationsType2Impact: 'Impact per retail area (top 25)',
            locationsGroupsType1Impact: 'Impact per format',
            locationsGroupsType2Impact: 'Impact per retail area type',
            locationsClustersType1Impact: 'Impact per retail area',
            locationsClustersType2Impact: 'Impact per municipality',
            locationsGroupsType1EmptyName: 'Independent',
            locationsGroupsType2EmptyName: '',
            locationsClustersType1EmptyName: '',
            locationsClustersType2EmptyName: '',
            locationGroupsType1Name: 'Format',
            locationGroupsType2Name: 'Retail area type',
            locationClustersType1Name: 'Retail area',
            locationClustersType2Name: 'Municipality',
            outputType1Name: "Turnover per week",
            outputType2Name: "Standard consumers",
            outputType3Name: "Turnover per year",
            outputType4Name: "Standard consumers",
            areasType1Name: "Zipcode | Zipcodes",
            areasType2Name: "Statistical sector | Statistical sectors",
            locationsType1New: "New location here",
            locationsType2New: "New retail area here",
            close: "Close",
            delete: "Delete",
            undoClose: "Undo close",
            undoEdit: "Undo edit",
            modelFunctionTypeToUse: "The following fields are possible to use:",
            ncalcMessage: "The functions are using the NCalc library. See https://github.com/ncalc/ncalc for more information.",
            calculationModelOutput: "Model results",
            locationsOutput: 'Locations',
            scenarioLocations: 'Locations',
            scenarioLocationsAllScenarios: 'Locations of all scenarios',
            scenarioLocationsOutput: 'Results',
            scenarioLocationsOutputDescription: 'Results of the selected scenario',
            scenarioLocationsOutputDescriptionWithHidden: 'Results of the selected scenario all fields',
            locationsOutputDescription: 'Locations.csv',
            catchmentAreaInformationExport: "Catchment area",
            catchmentAreaInformationExportCarDescription: "Catchment area car of the selected location",
            catchmentAreaInformationExportPedestrianDescription: "Catchment area pedestrian of the selected location",
            areaInformationExport: "Area result",
            areaInformationExportDescription: "Area result of the selected area",
            weight: "Weight",
            editNormalizedScoresFunctions: "Edit normalized scores functions",
            normalizedScoresFunctions: "Normalized scores functions",
            before: "Before",
            after: "After",
            difference: "Difference",
            localSituation: "Local situation",
            dontShow: "Don't show",
            pois: "POI's",
            by:"by",
            matchingLocations: "Matching locations",
            scenarioStatusEdited: "Edited",
            scenarioStatusWaiting: "Waiting for processing",
            scenarioStatusProcessing: "Processing",
            scenarioStatusCompleted: "Completed",
            scenarioStatusCompletedWithErrors: "Completed with errors",
            pleaseLogin: "Please login using the upper right menu.",
            busyLoading: "Busy loading...",
            searchMap: "Search the map",
        }
    },
    de: {
        message: {
            selectedScenario: "Ausgewähltes Szenario",
            submodel: "Submodell",
            confirmCalculateScenario:
                "Das Szenario wurde geändert. Möchten Sie das Szenario berechnen?",
            search: "Auf der Karte suchen",
            scenarioNameAutomaticallyChangedNotificaton:
                "Der Name des Szenarios wurde automatisch angepasst, da nur noch eine Änderung vorhanden war.",
            scenarioDeletedBecauseEmptyNotificaton:
                "Das Szenario enthielt keine Änderungen mehr und wurde daher gelöscht.",
            setScenarioNameMessage:
                "Da Sie ein Szenario mit mehreren Änderungen erstellen, müssen Sie den Namen des Szenarios angeben.",
            setScenarioName: "Szenario Name",
            saveAddToNewScenario: "Speichern (in neuem Szenario)",
            saveAddToThisScenario: "Speichern (im aktuellen Szenario)",
            saveAddToNewNestedScenario:
                "Speichern (in neuem verschachteltem Szenario)",
            saveEditInNewScenario: "Speichern (in neuem Szenario)",
            saveEditInThisScenario: "Speichern (im aktuellen Szenario)",
            saveEditInNewNestedScenario:
                "Speichern (in neuem verschachteltem Szenario)",
            cancel: "Abbrechen",
            closeMessage: "Sie sind dabei, den Standort zu schließen.",
            closeLocation: "Standort schließen",
            closeLocationInNewScenario: "Standort schließen (in neuem Szenario)",
            closeLocationInThisScenario: "Standort schließen (im aktuellen Szenario)",
            closeLocationInNewNestedScenario:
                "Standort schließen (in neuem verschachteltem Szenario)",

            nameRequired: "Name ist erforderlich",
            scenarioLocationsVisibleOnMap: "Standorte der Szenarien",
            scenarios: "Szenarien",
            errorOccured:
                "Ups! Etwas ist schiefgelaufen. Ein unerwarteter Fehler ist aufgetreten, möglicherweise aufgrund einer schlechten Internetverbindung. Bitte schließen Sie diese Meldung und versuchen Sie die Aktion erneut. Wenn das Problem weiterhin besteht, aktualisieren Sie bitte die Seite. Wenn der Fehler weiterhin auftritt, überprüfen Sie Ihre Internetverbindung oder wenden Sie sich an den Support.",
            noTopScenariosFound:
                "Es wurden keine Top-Szenarien gefunden. Erstellen Sie ein neues Top-Szenario oder bearbeiten Sie ein bestehendes Szenario als Top-Szenario.",
            filterScenarios: "Nach/in Szenarien suchen",
            locationInDefaultScenario:
                "Sie können das Standardszenario nicht ändern. Wenn Sie fortfahren, wird der Standort in einem neuen Szenario mit demselben Namen wie der Standort erstellt. Möchten Sie fortfahren?",
            generatedOn: "Generiert am",
            foundLocations: "Gefundene Standorte",
            of: "von",
            page: "Seite",
            selectAll: "Alle",
            selectNone: "Keine",
            heatMaps: "Wärmekarte",
            print: "Drucken",
            no: "Nein",
            yes: "Ja",
            copyChangesInformation:
                "Bitte geben Sie an, welche Änderungen in das Zielszenario kopiert werden sollen.",
            locations: "Standorte",
            copyChanges: "Änderungen kopieren",
            nonShared: "Nicht geteilt",
            sharedWith: "Geteilt mit",
            otherLocations: "andere Standorte",
            mergeScenarioSource:
                "Die folgenden Änderungen werden mit dem angegebenen Szenario zusammengeführt.",
            targetScenario: "Zielszenario",
            deleteSourceScenario: "Quellenszenario löschen",
            isTopScenarioDescription:
                "Ein Top-Szenario wird in der Liste der Szenarien zuerst und fett dargestellt.",
            mergeScenario: "Szenario zusammenführen",
            isTopScenario: "Top-Szenario",
            confirmClose:
                "Sind Sie sicher, dass Sie diesen Dialog schließen möchten?",
            displayName: "Anzeigename",
            email: "E-Mail-Adresse",
            mobilePhone: "Mobiltelefonnummer",
            calculationModel: "Berechnungsmodell",
            calculateUnprocessedScenarios: "Unverarbeitete Szenarien berechnen",
            log: "Protokoll",
            warning: "Warnung",
            eventType: "Typ",
            source: "Quelle",
            message: "Nachricht",
            fetchIsoLine: "Isochronen abrufen",
            isoLine: "Isochrone",
            scheduledDateTimeUtc: "Geplant",
            isoLinesMissingDateTimeUtc: "Fehlende Fahrzeiten",
            startedDateTimeUtc: "Start",
            endedDateTimeUtc: "Ende",
            noMatchingLocations: "Keine übereinstimmenden Standorte",
            scenarioNameIsRequired: "Szenario-Name ist erforderlich",
            parentScenario: "Quellenszenario",
            sourceLocations: "Quellstandorte",
            sourceLocationsAllScenarios: "Quellstandorte aller Szenarien",
            driverFilterFunction: "Funktion",
            driverFilterTitle: "Titel",
            addDriver: "Faktor hinzufügen",
            addDriverFilter: "Gruppe hinzufügen",
            confirmRemoveScenario:
                "Sind Sie sicher, dass Sie dieses Szenario löschen möchten?",
            confirmRemoveLocation:
                "Sind Sie sicher, dass Sie diesen Standort löschen möchten?",
            confirmUndoCloseLocation:
                "Sind Sie sicher, dass Sie das Schließen dieses Standorts rückgängig machen möchten?",
            confirmUndoEditLocation:
                "Sind Sie sicher, dass Sie die Bearbeitung dieses Standorts rückgängig machen möchten?",
            editScenario: "Bearbeiten",
            removeScenario: "Entfernen",
            calculateScenario: "Berechnen",
            calculateThisScenario: "Berechnen szenario",
            filterAndSearch: "Standorte",
            map: "Karte",
            matchingPercentage: "Übereinstimmung %",
            drawerNotCalculated:
                "Das Modell wurde nicht berechnet. Die Modellergebnisse sind nicht verfügbar.",
            drawerCalculating:
                "Das Modell wird berechnet. Warten Sie, bis die Ergebnisse verfügbar sind.",
            legend: "Legende",
            modelAdmin: "Modelladministrator",
            globalAdmin: "Globaler Administrator",
            noChanges: "Es gibt keine Öffnungen oder Schließungen.",
            name: "Name",
            query: "Suchtext",
            export: "Exportieren",
            noDrivers: "Für dieses Modell sind keine Faktoren definiert.",
            condition: "Bedingung",
            roundingDigits: "Dezimalstellen",
            inverse: "Invers",
            drivers: "Faktoren",
            report: "Bericht",
            matchingLocationsOutputChart: "Ergebnisdiagramm",
            matchingLocationsSimilarityChart: "Radar-Diagramm",
            driversChart: "Faktordiagramm",
            disclaimer: "Haftungsausschluss",
            disclaimerContent:
                "Die Entwicklung von IRIS basiert auf wissenschaftlichen Methoden, aktuellen Verkaufsstelleninformationen und sorgfältig recherchierten und aktuellen Kundeninformationen. Das Modell wird so gut wie möglich anhand der tatsächlich am Markt erzielten Umsätze und Informationen über das Funktionieren des Marktes validiert. Jedes mathematische Modell, wie detailliert es auch ausgearbeitet sein mag, ist eine vereinfachte Darstellung der Realität und wird niemals 100 % korrekt sein. Aus den Ergebnissen können daher keine Rechte abgeleitet werden.",
            help: "Handbuch",
            csvExport: "Daten exportieren",
            fileFormatCulture: "CSV-Spracheinstellungen",
            dutchFormat: "Niederländisches Format",
            englishFormat: "Englisches Format",
            areaInformation: "Gebietsinformationen",
            areaInformationCatchmentArea: "Gebietsinformationen Einzugsgebiet",

            adminPages: "Admin",
            locationAreas: "Gebiete des Standorts",
            adminOnly: "Nur für Administratoren (FUNKTIONIERT NOCH NICHT)",
            scenarioCompetition:
                "Standorte in diesem Szenario konkurrieren miteinander.",
            scenarioProcessingLog: "Verarbeitungsprotokoll",
            calculationScheduled: "Verarbeitung geplant",
            calculationWaitingForIsolines: "Verarbeitung wartet auf Isochronen",
            calculationStarted: "Verarbeitung gestartet",
            calculationEnded: "Verarbeitung beendet",
            calculationScenarioError: "Verarbeitungsfehler (Szenario)",
            calculationModelError: "Verarbeitungsfehler (Modell)",
            isolinesStarted: "Isochronenabruf gestartet",
            isolinesEnded: "Isochronenabruf beendet",
            isolinesError: "Isochronenabruf fehlgeschlagen",
            isolinesBusy: "Isochronenabruf läuft",
            dateAndTime: "Datum und Uhrzeit",
            action: "Aktion",
            model: "Modell",
            user: "Benutzer",
            last100records: "Letzte 100 Einträge",
            propertiesChart: "XY-Diagramm",
            propertyX: "Eigenschaft auf X-Achse",
            propertyY: "Eigenschaft auf Y-Achse",
            tree: "Baum",
            applicationTitle: "IRIS",
            title: "Titel",
            logout: "Abmelden",
            login: "Anmelden",
            share: "Anteil",
            area: "Gebiet | Gebiete",
            personType: "Persona",
            inhabitants: "Einwohner",
            houseHolds: "Haushalte",
            income: "Einkommen",
            areaRevenuePerYear: "Ausgaben in Postleitzahl pro Jahr",
            areaRevenuePerPeriod: "Ausgaben in Postleitzahl pro Zeitraum",
            areaStandardConsumers: "Standardkonsumenten in Postleitzahl",
            total: "Gesamt",
            travelTimeScores: "Attraktivität Reisezeit",
            from: "von",
            calculation: "Berechnung",
            perYear: "Pro Jahr",
            catchmentArea: "Einzugsgebiet",
            outputSpending: "Ausgaben",
            outputNumber: "Anzahl",
            car: "Auto",
            pedestrian: "Fußgänger",
            scorePersonType: "Attraktivität Persona",
            calculated: "Berechnet",
            location: "Standort",
            value: "Wert",
            defaultValue: "Standardwert",
            expression: "Funktion",
            format: "Format",
            scoresLocationGroup: "Attraktivität Format",
            scoresShop: "Attraktivität Geschäft",
            scoresRetailArea: "Attraktivität Einzelhandelsgebiet",
            retailArea: "Einzelhandelsgebiet",
            changedLocations: "Änderungen",
            opening: "Eröffnung",
            closure: "Schließung",
            edited: "Bearbeitet",
            edit: "Bearbeiten",
            addScenario: "Szenario hinzufügen",
            infoScenario: "Szenarioinformationen",
            calculate: "Berechnen",
            add: "Hinzufügen",
            info: "Information",
            scenarioCreated: "Erstellungsdatum",
            scenarioModified: "Bearbeitungsdatum",
            scenarioCreatedBy: "Erstellt von",
            scenarioModifiedBy: "Bearbeitet von",
            scenario: "Szenario",
            scenarioReport: "Auswirkungsbericht",
            scenarioName: "Szenarioname",
            save: "Speichern",
            error: "Fehler",
            url: "URL",
            status: "Status",
            description: "Beschreibung",
            settings: "Einstellungen",
            new: "Neu",
            modelParameters: "Modellparameter",
            none: "Keine",
            editModelParameters: "Modellparameter bearbeiten",
            editDrivers: "Faktoren bearbeiten",
            propertyType: "Typ",
            drawerEmptyText:
                "Klicken Sie auf einen Standort auf der Karte, um Informationen anzuzeigen.",
            score: "Attraktivität | Attraktivitäten",
            information: "Informationen",
            graphics: "Diagramme",
            layers: "Ebenen",
            all: "Alle",
            primary: "Primär (50%)",
            secondary: "Sekundär (80%)",
            impact: "Auswirkung",
            impactCar: "Auswirkung Auto",
            impactPedestrian: "Auswirkung Fußgänger",
            display: "Anzeige",
            top10: "Top 10",
            top25: "Top 25",
            top: "Top",
            fraction: "Anteil",
            fractionCumulative: "Anteil (kumulativ)",
            showTravelTimes: "Reisezeiten anzeigen",
            travelTimes: "Reisezeiten",
            minutes: "Minuten",
            group: "Gruppe",
            property: "Eigenschaft",
            expressions: "Funktionen",
            powerFactor: "Potenzfaktor",
            constant: "Konstante",
            scale: "Skala",
            max: "Maximum",
            by: "von", // Or "durch", depending on context
            fractionCutOffThreshold: "Schwellenwert für den Anteil",
            differenceDefaultScenario: "Unterschied zum Standardszenario",
            reports: "Berichte",
            locationsType1Name: "Standort | Standorte",
            locationsType2Name: "Einzelhandelsgebiet | Einzelhandelsgebiete",
            locationsType1FilterLocations: "Nach Standort des Szenarios filtern",
            locationsType2FilterLocations:
                "Nach Einzelhandelsgebiet des Szenarios filtern",
            googleSearch: "Auf der Karte suchen",
            locationType1Scores: "Attraktivität Standort",
            locationType2Scores: "Attraktivität Einzelhandelsgebiet",
            areaScores: "Attraktivität Reisezeiten",
            locationsType1Impact: "Auswirkung pro Standort (Top 25)",
            locationsType2Impact: "Auswirkung pro Einzelhandelsgebiet (Top 25)",
            locationGroupsType1Name: "Format",
            locationGroupsType2Name: "Art des Einzelhandelsgebiets",
            locationsGroupsType1Impact: "Auswirkung pro Format (Top 10)",
            locationsGroupsType2Impact:
                "Auswirkung pro Art des Einzelhandelsgebiets (Top 10)",
            locationsClustersType1Impact:
                "Auswirkung pro Einzelhandelsgebiet (Top 10)",
            locationsClustersType2Impact: "Auswirkung pro Gemeinde (Top 10)",
            locationsClustersType1EmptyName: "",
            locationsClustersType2EmptyName: "",
            locationsGroupsType1EmptyName: "Unabhängig",
            locationsGroupsType2EmptyName: "",
            locationClustersType1Name: "Einzelhandelsgebiet",
            locationClustersType2Name: "Gemeinde",
            outputType1Name: "Umsatz pro Woche",
            outputType2Name: "Standardkonsumenten",
            outputType3Name: "Umsatz pro Jahr",
            outputType4Name: "Standardkonsumenten",
            areasType1Name: "Postleitzahl | Postleitzahlen",
            areasType2Name: "Statistischer Sektor | Statistische Sektoren",
            locationsType1New: "Neuer Standort hier",
            locationsType2New: "Neues Einzelhandelsgebiet hier",
            close: "Schließen",
            delete: "Löschen",
            undoClose: "Schließen rückgängig machen",
            undoEdit: "Bearbeitung rückgängig machen",
            modelFunctionTypeToUse: "Die folgenden Felder können verwendet werden:",
            ncalcMessage:
                "Die Funktionen verwenden die NCalc-Bibliothek. Weitere Informationen finden Sie unter https://github.com/ncalc/ncalc.",
            calculationModelOutput: "Modellergebnisse",
            locationsOutput: "Standorte",
            scenarioLocationsOutput: "Ergebnisse",
            scenarioLocations: "Standorte",
            scenarioLocationsAllScenarios: "Standorte aller Szenarien",
            scenarioLocationsOutputDescription:
                "Ergebnisse des ausgewählten Szenarios",
            scenarioLocationsOutputDescriptionWithHidden:
                "Ergebnisse des ausgewählten Szenarios (alle Felder)",
            locationsOutputDescription: "Locations.csv",
            catchmentAreaInformationExport: "Einzugsgebiet",
            catchmentAreaInformationExportCarDescription:
                "Einzugsgebiet Auto des ausgewählten Standorts",
            catchmentAreaInformationExportPedestrianDescription:
                "Einzugsgebiet Fußgänger des ausgewählten Standorts",
            areaInformationExport: "Gebietsergebnis",
            areaInformationExportDescription:
                "Gebietsergebnis des ausgewählten Gebiets",
            weight: "Gewichtung",
            editNormalizedScoresFunctions:
                "Funktionen für normalisierte Werte bearbeiten",
            normalizedScoresFunctions: "Funktionen für normalisierte Werte",
            before: "Vorher",
            after: "Nachher",
            difference: "Differenz",
            localSituation: "Lokale Situation",
            dontShow: "Nicht anzeigen",
            pois: "POIs",
            matchingLocations: "Übereinstimmende Standorte",
            scenarioStatusEdited: "Bearbeitet",
            scenarioStatusWaiting: "Wartend auf Verarbeitung",
            scenarioStatusProcessing: "In Bearbeitung",
            scenarioStatusCompleted: "Abgeschlossen",
            scenarioStatusCompletedWithErrors: "Abgeschlossen mit Fehlern",
            pleaseLogin: "Bitte melden Sie sich über das Menü oben rechts an.",
            busyLoading: "Laden...",
            searchMap: "Karte durchsuchen",
        },
    }
}
export default messages;