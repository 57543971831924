
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    
    @Component
    export default class EditScenarioName extends Vue {
        @Prop({ default: null }) public scenario: any;

        private modalShow = false;
        private valid = true;
        private nameRules = [(value: any) => !!value || this.$t("message.scenarioNameIsRequired")]

        @Watch('scenario')
        private onScenarioChanged(value: any) {
            if (value) {
                this.modalShow = true;
            }
            else this.modalShow = false;
        }

        private save() {
            const valid = (this.$refs.form as any).validate();
            if (valid) this.scenario['save'] = true;
        }
    }
