
    import { Component, Vue } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';

    const namespace = 'main';
    @Component
    export default class ModelMenu extends Vue {
        @Getter('getModel', { namespace }) getModel: any;
        @Action('setModel', { namespace }) setModel: any;
        @Getter('getScenarios', { namespace }) getScenarios: any;
        @Getter('getSubModels', { namespace }) getSubModels: any;
        @Getter('getSubModel', { namespace }) getSubModel: any;
        @Action('setSubModel', { namespace }) setSubModel: any;
        @Getter('getModels', { namespace }) getModels: any;
        @Action('downloadFile') downloadFile: any;

        private get isAuthenticated(): boolean {
            if (this.$msal == null) return true;
            return this.$msal.isAuthenticated;
        }

        private get models(): any {
            return this.getModels;
        }

        private get model(): any {
            const model = this.getModel;
            if (model != null) this.$i18n.locale = model.language;
            return model;
        }

        private set model(value: any) {
            this.setModel(value);
        }

        private get subModels(): any {
            return this.getSubModels;
        }

        private get subModel(): any {
            return this.getSubModel;
        }
        private set subModel(value: any) {
            this.setSubModel(value);
        }
    }
