
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Bar } from 'vue-chartjs'
    @Component({
        components: {
            Bar
        },
    })
    export default class MatchingLocationsOutputChart extends Vue {
        @Prop({ default: null }) public matchingLocations: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public matchingColors: any;
        private setLocation(location: any) {
            this.$emit('locationChanged', location);
        }
        get chartData() {
            const self = this;
            if (this.matchingLocations != null && this.matchingLocations.length > 0) {
                const data: any = [];
                const dataActual: any = [];
                const labels: any = [];
                this.matchingLocations.slice(0, 6).forEach(function (output: any) {
                    labels.push(output.name + ' ' + Math.round(output.score * 1000) / 10 + "%");
                    data.push(output.outputLocationPropertyValue);
                    dataActual.push(output.actualOutputLocationPropertyValue);
                });

                const dataSets: any = [];
                dataSets.push(
                    {
                        axis: 'y',
                        label: this.matchingLocations[0].outputLocationPropertyTitle,
                        data: data,
                        backgroundColor: self.matchingColors,
                        barPercentage: 0.1,
                        barThickness: 28,
                        categoryPercentage: 0.15,
                        borderWidth: 1
                    }
                );
                if (this.matchingLocations[0].actualOutputLocationPropertyValue != null) {
                    dataSets.push(
                        {
                            axis: 'y',
                            label: this.matchingLocations[0].actualOutputLocationPropertyTitle,
                            data: dataActual,
                            datalabels: {
                                color: '#000000',
                                align: 'right'
                            },
                            backgroundColor: '#cccccc',
                            barThickness: 28,
                            barPercentage: 0.1,
                            categoryPercentage: 0.15,
                            borderWidth: 1
                            //borderWidth: 10,
                            //: 'transparent'
                        }
                    )
                }

                return {
                    labels: labels,
                    datasets: dataSets
                }
            }
            else {
                return null;
            }
        }
        get chartOptions() {
            const self = this;
            return {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                animation: {
                    duration: 0
                },
                scales: {
                    x: {
                        border: {
                            display: false
                        },
                        grid: {
                            display: false
                        },
                        ticks: {
                            display: false
                        }
                    },
                    y: {
                        border: {
                            display: false
                        },
                        grid: {
                            display: false
                        },
                        ticks: {
                            crossAlign: "far",
                            font: {
                                size: '14px',
                            }
                        },
                    }
                },
                scale: {
                    ticks: {
                        maxTicksLimit: 0
                    },
                },
                plugins: {
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: '#ffffff',
                        formatter: function (value: any) {
                            if (self.model.outputType != 4) {
                                return self.$options.filters.currency(value, self.model.language, self.model.currency);
                            }
                            else {
                                return self.$options.filters.fixed(value, 0, self.model.language);
                            }
                        },
                    }
                }
            }
        }
    }
