
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class Dialog extends Vue {
        @Prop({ default: null }) public show: any;
        @Prop({ default: false }) public persistent: any;

        private get localShow() {
            return this.show;
        }

        private set localShow(value: any) {
            this.$emit("showChanged", value);
        }
    }
