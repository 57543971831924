
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import PropertyValue from '@/components/main/PropertyValue.vue'
    import Properties from '@/components/main/Properties.vue'
    import Loading from '@/components/main/Loading.vue';

    @Component({
        components: {
            PropertyValue,
            Properties,
            Loading,
        },
    })
    export default class CatchmentAreaInformation extends Vue {
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public locationCatchmentArea: any;
        @Prop({ default: null }) public locationProperties: any;
        
        private setArea(areaCode: any) {
            this.$emit('areaSelected', areaCode);
        }
        get headers(): any {
            return [
                {
                    value: 'areaCode',
                }
            ];
        }
        get filteredAreas() {
            const _self = this;
            if (this.locationCatchmentArea?.areas == null) return [];
            const _filteredAreas = this.locationCatchmentArea.areas.filter(function (area: any) {
                return area.locationPropertyId + 0 == _self.model.outputLocationPropertyId + 0;
            });
            if (_filteredAreas.length == 0) return this.locationCatchmentArea.areas; else return _filteredAreas;
        }

        private get totalOutput() {
            let value = 0;
            this.locationCatchmentArea.areas.forEach(function (area: any) {
                value += area.output;
            });
            return value;
        }

        private get totalOutputDefault() {
            let value = 0;
            this.locationCatchmentArea.areas.forEach(function (area: any) {
                value += area.outputDefaultScenario;
            });
            return value;
        }
    }
